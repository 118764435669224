<template>
    <div>
        <div class="main">

            <div class="nav">
                <div :class="active_index == index?'active_item':'nav_item'" v-for="(item,index) in nav" :key="index" @click="change_type(index)">
                     {{item}}
                </div>
            </div>
            <div class="list_box">
                <div class="list_item" v-for="(item,index) in order_list" :key='index' @click="jump(item.id)">
                    <div class="img_text">
                        <img :src="item.cover" alt="">
                        <div class="small_mask text_left">
                            {{item.type_name}} 
                        </div>
                    </div>
                    <div class="major_detail">
                        {{item.brief}}
                    </div>
                    <div class="money">
                        {{item.ctime}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            nav:['行业资讯','投标策略','法律法规'],
            active_index:0,
            searData:{
                    type_id:3,
                    limit:'',
                    page:''
			},
			order_list:''
        }   
    },

    created(){
        this.loadData()
    },
    methods:{

        loadData(){

                this.$api.post('articles.getArticleList',this.searData,(res)=>{
					if(res.data.status){
						this.order_list = res.data.data.list
					}else{
						this.$message.error(res.data.msg)
					}
			    })
            // }
            
        },
        change_type(val){
            this.active_index = val
            switch (val){
                case 0:
                    this.searData.type_id = 3
                    this.loadData()
                    break;
                case 1:
                    this.searData.type_id = 4
                    this.loadData()
                    break;
                case 2:
                    this.searData.type_id = 5
                    this.loadData()
                    break;
            }
        },
        jump(id){
            this.$router.push({path:'/artical',query:{id:id}})
        },

        get_chat(){
            document.body.style.overflow = ''
            this.$router.push({path:'/chat',query:{order_id:this.vip_order}})
        }
    }
}
</script>
<style lang="scss" scoped>
.button_chat{
    margin: 0 auto;
    margin-top: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    color: #fff;
    width: 279px;
    height: 60px;
    background: #DEAA8A;
    opacity: 1;
    border-radius: 10px;
    img{
        height:22px;
        width: 19px;
        margin-right: 11px;
        border: 1px red solid;
    }
}
.pay{
    margin-top: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color:#666;
    img{
        height: 33px;
        width: 33px;
        margin-right: 5px;
    }
}
.order_no{
    text-align: left;
    font-size: 22px;
    color: #666;
    margin: 41px 0 0 124px;
}
.title{
    font-size: 22px;
    font-weight: bold;
    margin-top: 57px;
    margin-left: 50px;
}
.order_desc{
    display: flex;
    align-items: center;;
    margin-top: 41px;
    height: 33px;
    line-height: 33px;
    text-align: left;
    font-size: 22px;
    color: #666;
    img{
        height: 33px;
        width: 28px;
        margin: 0px 17px 0 72px;
    }
}
.mask_box{
    position: relative;
    width: 800px;
    height: 510px;
    background: #FFFFFF;
    opacity: 1;
    border-radius: 30px;
    .close_img{
        height:32px;
        width: 32px;
        position: absolute;
        right: 20px;
        top: 20px;
    }
}
.mask{
      position: fixed;
      top: 0;
      left: 0;
      z-index: 999;
      height: 100%;
      width: 100%;
      background: rgba($color: #000000, $alpha: .3);
      display: flex;
      align-items: center;
      justify-content: center;
  }
    .list_box{
        margin-top: 30px;
        display: flex;
        flex-wrap: wrap;
        // justify-content: center;
        width: 1179px;
        .list_item{
            
           margin-right: 30px;
           background: #FFF;
           height: 376px;
           width: 360px;
           margin-bottom: 32px;
           border-radius: 10px;
           .img_text{
               position: relative;
               img{
                   border-radius: 10px 10px 0 0;
                   height: 214px;
                   width: 360px;
               }
               .small_mask{
                   padding-left: 20px;
                   position: absolute;
                   bottom: 5px;
                   height: 40px;
                   width: 340px;
                   line-height: 40px;
                   background-color: rgba($color: #000000, $alpha: .35);
                   color: #FFF;
                   font-size: 22px;
               }
           }
           .major_detail{
               text-align: left;
               margin: 0 auto;
               margin-top: 15px;
               height: 80px;
               width: 332px;
               font-size: 14px;
               overflow: hidden;
           }
           .money{
               display: flex;
               align-items: center;
               justify-content: flex-end;
               .chat_button{
                   margin-left: 10px;
                   margin-right: 20px;
                   font-size: 14px;
                   height: 26px;
                   width: 80px;
                   text-align: center;
                   line-height: 26px;
                   color: #144591;
                   border: 1px #144591 solid;
                   border-radius: 20px;
               }
           }

        }
    }
    .popup{
      position: relative;
      width: 786px;
        height: 533px;
        background: #FFFFFF;
        border: 1px solid #707070;
        opacity: 1;
        border-radius: 30px;
  }
   .img_style{
      position: relative;
      height: 430px;
      width: 462px;
      margin: 46px 0 0 168px;
      background-image: url('../assets/service.png');
  }
  
  .close{
      height: 36px;
      width: 36px;
      position: absolute;
      top: 24px;
      right: 24px;
  }

  .phone{
      font-size: 30px;
      color: #FFF;
      position: absolute;
      bottom: 9px;
      left: 74px;
  }

    .main{
        padding: 30px;
        margin: 40px auto;
        // width: 1140px;
        background: #F1F4F8;
        opacity: 1;
        border-radius: 30px;
    }
    .nav{
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        .nav_item{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 360px;
            height: 80px;
            background: #FFFFFF;
            color:#666;
            opacity: 1;
            border-radius: 10px;
            font-size: 22px;
        }
        .active_item{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 360px;
            height: 80px;
            background: #144591;
            opacity: 1;
            border-radius: 10px;
            color: #FFFFFF;
            font-size: 22px;
        }
    }
</style>